import { type ScopedAggregatorResponse } from '../aggregator-types';

export enum Scope {
	Loom = 'loom.loom',
}

interface LoomItemResponseItem {
	activityDate?: string;
	icon: string;
	iconAlt: string;
	name: string;
	url: string;
	videoCreatorName: string;
	workspaceName: string;
	createdAt?: string;
}

export interface LoomItemResponse extends ScopedAggregatorResponse<Scope.Loom> {
	results: LoomItemResponseItem[];
	size?: number;
}

export type LoomResponse = LoomItemResponse;
