import {
	Scope as ConfluenceScope,
	type PageBlogResponse,
	type SupportedScopedResponses,
} from './confluence';
import { type IssueResponse, Scope as JiraScope, type SupportedResponseTypes } from './jira';
import { type LoomItemResponse, Scope as LoomScope } from './loom';
import { type AggregatorTownsquareResponse, TownsquareScope } from './townsquare';

export interface ModelParam {
	'@type': string;
	[value: string]: string | number;
}

export interface SearchContext {
	sessionId: string;
	referrerId: string | null;
}

export interface ABTest {
	abTestId: string;
	controlId: string;
	experimentId: string;
}

export interface ExperimentResponse<T> {
	scopes: {
		id: T;
		abTest: ABTest;
	}[];
}

export type CommonFilterType = 'site';

/**
 * All filters must implement this interface.
 */
export interface FilterInterface {
	'@type': string;
}

export enum Products {
	jira = 'JIRA',
	confluence = 'CONFLUENCE',
	townsquare = 'TOWNSQUARE',
	trello = 'TRELLO',
	bitbucket = 'BITBUCKET',
	other = 'OTHER',
}

export interface Site {
	product: Products;
	avatarUrl: string;
	cloudId: string;
	collabGraphSessionId?: string;
	siteName: string;
	siteUrl: string;
}

export interface SearchAnalyticParams {
	/**
	 * Used to tag analytics so it can be differentiated with other consumers of search,
	 * Example: 'media-editor' or 'portfolio-search'
	 */
	identifier: string;
	/**
	 * This is used to group together multiple searches.
	 * If used this should be a UUID.
	 *
	 * Note that the search client does not maintain any logic as to when this is invalidated it only guarantees that this is passed
	 * to the backend.
	 */
	sessionId?: string;
}

export type AllSupportedResponseAndScopes =
	| SupportedScopedResponses
	| SupportedResponseTypes
	| AggregatorTownsquareResponse
	| LoomItemResponse;

export type Scope = AllSupportedResponseAndScopes['id'];
export type { PageBlogResponse, IssueResponse };

export const Scope = {
	...ConfluenceScope,
	...JiraScope,
	...TownsquareScope,
	...LoomScope,
};
