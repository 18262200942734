import { type ScopedAggregatorResponse } from '../aggregator-types';

/*
These types copied from
packages/search/product-search-dialog/src/extensible/products/townsquare/types.tsx
*/
export enum TownsquareScope {
	TownsquareProject = 'townsquare.project',
	TownsquareGoal = 'townsquare.goal',
	TownsquareHelpLink = 'townsquare.help-pointer',
	TownsquareQuestion = 'townsquare.question',
	TownsquareTag = 'townsquare.tag',
	TownsquareLearning = 'townsquare.learning',
	People = 'townsquare.people',
	Teams = 'townsquare.teams',
}

export interface TownsquareResponse {
	id: string;
	url: string;
	title: string;
	iconUrl: string;
}

export interface PeopleResponse {
	absoluteUrl?: string; // For any TCS errors this field will not be present in the response.
	account_id: string;
	name: string;
	nickname?: string;
	job_title?: string;
	picture: string;
}

export interface AggregatorTownsquareResponse extends ScopedAggregatorResponse<TownsquareScope> {
	results: TownsquareResponse[];
}

// End of copied code.
